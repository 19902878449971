<template>
  <div class="add">
    <div class="btns" v-show="!currentStep2">
      <el-button class="iconfont icon-dcarrow-left" type="primary" v-show="currentStep1" @click="front">上一步</el-button>
      <el-button type="primary" @click="next" v-show="currentStep0">下一步</el-button>
      <el-button class="iconfont icon-dcicon_save1" type="primary" v-show="currentStep1" @click="submit">保存</el-button>
      <el-button class="iconfont icon-dcx" @click="cancel">取消</el-button>
    </div>
    <base-row class="steps">
      <base-col :num="24">
        <steps :active="active" :steps="['填写模板信息', '确认模板信息', '完成']" />
      </base-col>
    </base-row>
    <div class="main">
      <keep-alive>
        <component ref="component" :is="currentComponent" :form="form" :deviceTypes="deviceTypes" :groups="groups" :users="users" :type="type" @go_on="go_on"></component>
      </keep-alive>
    </div>
    <router-view></router-view>
    <Drawer staticIcon title="引用列表" AlertTitle="此次修改，会同步更新以下引用的设备" :drawer="drawer" :DeviceData="drawerData" @closeDrawer="drawer = false" @clickbtn="clickbtn" />
  </div>
</template>

<script>
import baseRow from '@/components/row/base-row';
import baseCol from '@/components/col/base-col';
import step0 from './step0.vue';
import step1 from './step1.vue';
import step2 from './step2.vue';
import Drawer from '@/components/drawer/index.vue';
import steps from '@/components/steps/base-steps';
export default {
  data() {
    return {
      drawer: false,
      drawerData: [],
      // 当前页面类型，add 添加 edit 修改
      type: 'add',
      // 当前步骤
      active: 0,
      form: {
        name: '',
        deviceTypeId: '',
        paramGroupId: '',
        userId: '',
        iconId: '',
        iconUrl: '',
        templateId: this.$route.query.templateId || ''
        // files: [],
        // 删除文件ID集合，编辑时会用到
        // fileIds: [],
        // basicInfos: []
      },
      deviceNumber: 0,
      deviceTypes: [],
      groups: [],
      users: [],
      currentComponent: step0
    };
  },
  created() {
    this.checkType();
    this.loadData();
  },
  methods: {
    // 检查当前页面类型
    checkType() {
      this.type = this.$route.query.type || this.type;
    },
    // 上一步
    front() {
      this.active = 0;
    },
    // 下一步
    next() {
      if (this.$refs.component.check) {
        const check = this.$refs.component.check();
        if (!check) {
          return;
        }
      }

      // const basicInfoFill = this.form.basicInfos.some(item => !item.displayName || !item.displayValue);
      // if (basicInfoFill) {
      //   this.$message.error('请填写完整的字段名称和字段内容');
      //   return;
      // }
      if ((this.active === 0 && this.$refs.component.validateStatus) || this.active === 1) {
        this.active++;
      }
    },
    // 保存
    async submit() {
      if (this.type === 'add') {
        const params = {
          ...this.form
          // basicInfos: JSON.stringify(this.form.basicInfos)
        };

        const { fail } = await this.$apis.deviceTemplate.save(params);
        if (!fail) {
          this.next();
        }
      }

      if (this.type === 'edit') {
        if (this.deviceNumber) {
          this.drawer = true;
          this.drawerData = await this.$apis.deviceTemplate.getDeviceList({ templateId: this.form.templateId });
          return;
        }

        const params = {
          ...this.form
          // basicInfos: JSON.stringify(this.form.basicInfos)
        };

        const { fail } = await this.$apis.deviceTemplate.update(params);
        if (!fail) {
          this.next();
        }
      }
    },
    // 取消
    cancel() {
      this.$router.back();
    },
    // 继续添加
    go_on() {
      this.$router.go(0);
    },
    // 加载数据
    async loadData() {
      this.groups = await this.$apis.paramGroup.listAll();
      this.deviceTypes = await this.$apis.deviceType.listAll();
      this.users = await this.$apis.sysUser.listAll();
      if (this.type === 'edit') {
        this.loadEditData();
      }
    },
    // 加载修改数据
    async loadEditData() {
      const { name, deviceTypeId, paramGroupId, iconId, iconUrl, userId, deviceNumber } = await this.$apis.deviceTemplate.get({
        templateId: this.form.templateId
      });
      this.form.name = name;
      this.form.deviceTypeId = deviceTypeId;
      this.form.paramGroupId = paramGroupId;
      this.form.userId = userId;
      // this.form.files = fileVos || [];
      this.form.iconUrl = iconUrl;
      this.form.iconId = iconId;
      // this.form.basicInfos = basicInfoList;
      this.deviceNumber = deviceNumber;
    },
    async clickbtn() {
      const params = {
        ...this.form
        // basicInfos: JSON.stringify(this.form.basicInfos)
      };
      const { fail } = await this.$apis.deviceTemplate.update(params);
      if (!fail) {
        this.drawer = false;
        this.next();
      }
    }
  },
  watch: {
    active(val) {
      switch (val) {
        case 0:
          this.currentComponent = step0;
          break;
        case 1:
          this.currentComponent = step1;
          break;
        case 2:
          this.currentComponent = step2;
          break;
      }
    }
  },
  computed: {
    currentStep0() {
      return this.active === 0;
    },
    currentStep1() {
      return this.active === 1;
    },
    currentStep2() {
      return this.active === 2;
    }
  },
  components: {
    baseRow,
    baseCol,
    Drawer,
    steps
  }
};
</script>

<style lang="scss" scoped>
.steps {
  margin-top: 48px;
}
</style>
