<template>
  <div class="base-step">
    <el-steps :active="active" align-center finish-status="success">
      <el-step :title="step" v-for="step in steps" :key="step"></el-step>
    </el-steps>
  </div>
</template>

<script>
export default {
  props: {
    active: {
      type: Number,
      default: 0
    },
    'align-center': Boolean,
    'finish-status': {
      type: String,
      default: 'success'
    },
    steps: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
@import '@/common/style/var.scss';
/deep/ .el-steps {
  .el-step__icon {
    width: 40px;
    height: 40px;
    font-size: 20px;
  }
  .el-step__head {
    &.is-process {
      color: $--color-primary;
      border-color: $--color-primary;
    }
    &.is-success {
      color: $--color-primary;
      border-color: $--color-primary;
      .el-step__line {
        background: $--color-primary;
      }
    }
  }
  .el-step__line {
    color: $--color-primary;
    border-color: $--color-primary;
  }
  .el-step__main {
    .el-step__title {
      &.is-process {
        color: $--color-primary;
      }
      &.is-success {
        color: $--color-primary;
      }
    }
  }
  .is-horizontal {
    .el-step__line {
      top: 20px !important;
    }
  }
}
</style>
