<template>
  <div class="step3">
    <el-result icon="success" :title="mapping[type].title" :subTitle="mapping[type].desc">
      <template slot="extra">
        <el-button type="primary" size="small" @click="goBack">返回列表</el-button>
        <el-button size="small" @click="addDevice">添加设备</el-button>
        <el-button size="small" @click="go_on">继续添加</el-button>
      </template>
    </el-result>
  </div>
</template>

<script>
export default {
  props: {
    type: String
  },
  data() {
    return {
      mapping: {
        add: {
          title: '添加成功',
          desc: '设备模板创建成功！可以在设备管理中引用该模板'
        },
        edit: {
          title: '修改成功',
          desc: '设备模板修改成功！可以在设备管理中引用该模板'
        }
      }
    };
  },
  methods: {
    goBack() {
      this.$router.push({ name: 'template' });
    },
    addDevice() {
      this.$router.push('/deviceInfo');
    },
    go_on() {
      this.$emit('go_on');
    }
  }
};
</script>

<style lang="scss" scoped>
.step3 {
  margin-top: 120px;
}
</style>
