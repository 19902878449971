<template>
  <div class="step1">
    <el-alert title="保存前，请核对您的模板内容" show-icon type="warning" :closable="false"></el-alert>
    <h2 class="page-name">模板预览</h2>
    <div class="hd">
      <img :src="BASE_URL + form.iconUrl" alt="" class="hd-img" />
      <div class="hd-main">
        <h3 class="template-name">{{ form.name }}</h3>
        <!-- <ul class="file-list">
          <li class="file-item" v-for="file in form.files" :key="file.uid">
            <i class="file-icon el-icon-tickets"></i>
            <p class="file-name">{{ file.name }}</p>
          </li>
        </ul> -->
      </div>
    </div>
    <el-form label-width="80px">
      <baseSection name="基础信息">
        <baseRow>
          <baseCol>
            <el-form-item label="负责人: ">
              <p>{{ mapping(users, 'id', form.userId).realName }}</p>
            </el-form-item>
          </baseCol>
          <baseCol>
            <el-form-item label="配置参数: ">
              <p>{{ mapping(groups, 'groupId', form.paramGroupId).name }}</p>
            </el-form-item>
          </baseCol>
          <baseCol>
            <el-form-item label="设备类型: ">
              <p>{{ mapping(deviceTypes, 'deviceTypeId', form.deviceTypeId).name }}</p>
            </el-form-item>
          </baseCol>
        </baseRow>
      </baseSection>
      <!-- <baseSection name="扩展信息">
        <baseRow>
          <baseCol>
            <el-form-item :label="item.displayName + ':'" v-for="(item, index) in this.form.basicInfos" :key="index">
              <p>{{ item.displayValue }}</p>
            </el-form-item>
          </baseCol>
        </baseRow>
      </baseSection> -->
    </el-form>
  </div>
</template>

<script>
import baseSection from '@/components/section/base-section';
import baseRow from '@/components/row/base-row';
import baseCol from '@/components/col/base-col';

export default {
  props: {
    form: Object,
    deviceTypes: Array,
    groups: Array,
    users: Array
  },
  data() {
    return {
      BASE_URL: process.env.VUE_APP_ICONIMG_URL
    };
  },
  methods: {
    mapping(arr, key, id) {
      return arr.filter(item => item[key] === id)[0];
    }
  },
  components: {
    baseSection,
    baseRow,
    baseCol
  }
};
</script>

<style lang="scss" scoped>
@import '@/common/style/var';
.step1 {
  margin-top: 24px;
}
.page-name {
  font-size: 18px;
  margin: 50px 0 23px 0;
}
.hd {
  display: flex;
  margin-left: 120px;
  .hd-img {
    width: 90px;
    height: 90px;
  }
  .hd-main {
    margin: 15px 0 0 24px;
    .template-name {
      font-size: 18px;
    }
    // .file-list {
    //   width: 500px;
    //   height: 83px;
    //   .file-item {
    //     display: flex;
    //     align-items: center;
    //     .file-icon,
    //     .file-name {
    //       color: $--color-primary;
    //     }
    //     .file-name {
    //       overflow: hidden;
    //       text-overflow: ellipsis;
    //       white-space: nowrap;
    //       margin-left: 9px;
    //     }
    //   }
    // }
  }
}
</style>
