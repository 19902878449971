<template>
  <div class="step1">
    <el-form :model="form" :rules="rules" ref="form" label-width="100px" class="el-form">
      <baseSection name="基础信息">
        <baseRow justify="space-between">
          <baseCol>
            <el-form-item label="模板名称" prop="name"> <el-input v-model.trim="form.name" placeholder="请输入模板名称"></el-input> </el-form-item>
          </baseCol>
          <baseCol>
            <el-form-item label="参数组" prop="paramGroupId">
              <el-select v-model="form.paramGroupId" placeholder="请选择参数组">
                <el-option v-for="item in groups" :key="item.groupId" :label="item.name" :value="item.groupId"></el-option>
              </el-select>
            </el-form-item>
          </baseCol>
          <baseCol>
            <el-form-item label="设备类型" prop="deviceTypeId">
              <el-select v-model="form.deviceTypeId" placeholder="请选择设备类型">
                <el-option v-for="item in deviceTypes" :key="item.deviceTypeId" :label="item.name" :value="item.deviceTypeId"></el-option>
              </el-select>
            </el-form-item>
          </baseCol>
        </baseRow>
        <baseRow>
          <baseCol>
            <el-form-item label="负责人" prop="userId">
              <el-select v-model="form.userId" placeholder="请选择负责人">
                <el-option v-for="item in users" :key="item.id" :label="item.realName" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </baseCol>
        </baseRow>
      </baseSection>
      <baseSection name="上传资料">
        <baseRow justify="space-between">
          <baseCol>
            <el-form-item label="模板图片">
              <Upload
                :setimgUrl="form.iconUrl"
                @getimgUrl="
                  item => {
                    form.iconUrl = item;
                  }
                "
                :currenttype="3"
                @geticonId="
                  item => {
                    form.iconId = item;
                  }
                "
              ></Upload>
            </el-form-item>
          </baseCol>
          <!-- <baseCol :num="12">
            <el-form-item label="模板文档">
              <el-upload
                class="upload"
                multiple
                action=""
                :auto-upload="false"
                accept=".doc,.docx,.xls,.xlsx,.pdf,.txt"
                :limit="UPLOAD_LIMIT"
                :file-list="form.files"
                :on-change="changeFile"
                :on-remove="fileRemove"
              >
                <el-button class="iconfont icon-dca-shangchuan1" :disabled="disabledUploadFile">点击上传</el-button>
                <div slot="tip" class="el-upload__tip">格式只支持：word、Excel、PDF，txt，且总数不得超过5个，每个文件大小不超过1M</div>
              </el-upload>
            </el-form-item>
          </baseCol> -->
          <!-- <baseCol :num="2"></baseCol> -->
        </baseRow>
      </baseSection>
      <!-- <baseSection name="扩展信息">
        <baseRow>
          <baseCol :num="7">
            <el-form-item :label="`字段${index + 1}`" prop="basicInfos" v-for="(item, index) in form.basicInfos" :key="index">
              <div>
                <el-input v-model="item.displayName" placeholder="请输入字段名称"></el-input>
                <i class="del el-icon-delete" @click="delBasicInfo(index)"></i>
              </div>
              <div>
                <el-input v-model="item.displayValue" placeholder="请输入字段内容"></el-input>
              </div>
            </el-form-item>
            <el-form-item :label="form.basicInfos.length ? '' : '自定义字段'">
              <el-button class="iconfont icon-dctianjia" style="width: 100%" @click="addBaseInfo">添加字段</el-button>
            </el-form-item>
          </baseCol>
        </baseRow>
      </baseSection> -->
    </el-form>
  </div>
</template>

<script>
import baseSection from '@/components/section/base-section';
import baseRow from '@/components/row/base-row';
import baseCol from '@/components/col/base-col';
// import { UPLOAD_LIMIT } from '/app.config';
// import { checkFizeSize } from '@/common/js/utils';
import Upload from '@/components/upload/uploadimg/index.vue';
export default {
  props: {
    form: Object,
    deviceTypes: Array,
    groups: Array,
    users: Array
  },
  data() {
    return {
      // UPLOAD_LIMIT,
      // 表单是否全都校验通过
      validateStatus: false,
      rules: {
        name: [{ required: true, message: '请输入模板名称', trigger: 'blur' }],
        paramGroupId: [{ required: true, message: '请输选择参数组', trigger: 'change' }],
        deviceTypeId: [{ required: true, message: '请选择设备类型', trigger: 'change' }],
        userId: [{ required: true, message: '请输负责人', trigger: 'change' }]
        // basicInfos: [{ required: true, message: '请输入' }]
      }
    };
  },

  methods: {
    check() {
      this.$refs.form.validate(valid => {
        this.validateStatus = valid;
      });
      return this.validateStatus;
    }
    // addBaseInfo() {
    //   this.form.basicInfos.push({ displayName: '', displayValue: '' });
    // },
    // changeFile(file, fileList) {
    //   const below1M = checkFizeSize.below1M(file.size);

    //   if (below1M) {
    //     this.form.files = fileList;
    //   } else {
    //     fileList.pop();
    //     this.$message.error('文件大小不能超过 1M');
    //   }
    // },
    // fileRemove(file, fileList) {
    //   this.form.files = fileList;
    //   this.form.fileIds.push(file.id);
    // },
    // delBasicInfo(index) {
    //   this.form.basicInfos.splice(index, 1);
    // }
  },
  // watch: {
  //   'form.files'(val) {
  //     if (val.length) {
  //       this.$refs.form.clearValidate('files');
  //     }
  //   }
  // },
  // computed: {
  //   disabledUploadFile() {
  //     const input = document.querySelector('.upload .el-upload__input');
  //     if (this.form.files.length === UPLOAD_LIMIT) {
  //       input.setAttribute('disabled', 'disabled');
  //       return true;
  //     } else {
  //       if (input) {
  //         input.removeAttribute('disabled');
  //       }
  //       return false;
  //     }
  //   }
  // },
  components: {
    baseSection,
    baseRow,
    baseCol,
    Upload
  }
};
</script>

<style lang="scss" scoped>
@import '@/common/style/var';
.step1 {
  margin-top: 75px;
}
// .upload {
//   .el-upload__tip {
//     color: $--color-text-secondary;
//     font-size: 12px;
//   }
// }

.el-input,
.el-select {
  width: 100%;
}

// .del {
//   position: absolute;
//   right: -25px;
//   top: 10px;
//   cursor: pointer;
// }
</style>
